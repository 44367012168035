import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { motion, AnimatePresence } from 'framer-motion';

const FancyBox = props => {
  const { youtubeVideoId, showPopup } = props;
  const [ isOpen, setIsOpen ] = useState(false);

  const url = `https://www.youtube-nocookie.com/embed/${youtubeVideoId}`;
  const youtubeConfig = {
    youtube: {
      autoplay: 1,
      controls: 1,
      modestbranding: 0,
      showinfo: 0,
      fs: 1,
      cc_load_policy: 0,
      iv_load_policy: 3,
      autohide: 0,
    },
  };

  const clickHandler = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(!isOpen);
  }, [ showPopup ]);

  return (
    <AnimatePresence>
      {!isOpen
        && (
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
            className='video-popup'
          >
            <span className='video-popup__overlay' onClick={e => clickHandler(e)} />
            <button type='button' className='video-popup__close' onClick={e => clickHandler(e)}>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                <path d='M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z' />
              </svg>
            </button>
            <div className='video-popup__inner'>
              <ReactPlayer
                className='video-popup__iframe'
                url={url}
                controls
                playing
                width='100%'
                height='100%'
                config={youtubeConfig}
              />
            </div>
          </motion.div>
        )}
    </AnimatePresence>
  );
};

export default FancyBox;
